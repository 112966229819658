// Here you can add other styles
.tssn img {
    aspect-ratio: auto;
    width: 100%;
    max-width: 1200px;
    background-color: #333;
    padding: 5px;
}

.tssn-mobile-logo {
    border-radius: 5px;
    padding: 5px;
    background-color: #3c4b64;
}

.nav-link {
    text-wrap: wrap !important;
}

.sidebar {
    --cui-sidebar-width: 20rem;
}

.sidebar:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 20rem;
}

.sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 4rem;
}

.tssn-dash-button {
    background-color: rgb(60, 75, 100,.75) !important;
    border-color: rgb(60, 75, 100, .9) !important;
}


.tssn-card-right {
    color: #3c4b64;
    margin-left: .5rem;
}
